<script>
export default {

  props: ['title'],
  data() {
    return {

      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      
      orderData: [
        {
          
          date: "1 Jun, 11:21",
          name: "Alex Fox",
          status: 'On board',
          Description: "Wireframing Kit for Figm, ",
        },
        {

          date: "29 May, 18:36",
          name: "Joya Calvert",
          status: 'Quit',
          Description: "Mastering the Grid +2 more ",
        },
        {

          date: "25 May , 08:09",
          name: "Gracyn Make",
          status: 'Sick Leava',
          Description: "Wireframing Kit for Figma ",
        },
        {

          date: "19 May , 14:09",
          name: "Monroe Mock",
          status: 'On board',
          Description: "Spiashify 2.0",
        },
        {

          date: "10 May , 10:00",
          name: "Lauren Bond",
          status: 'On board',
          Description: "Mastering the Grid",
        },
        {

date: "25 May , 08:09",
name: "Gracyn Make",
status: 'Sick Leava',
Description: "Wireframing Kit for Figma ",
},
{

date: "19 May , 14:09",
name: "Monroe Mock",
status: 'On board',
Description: "Spiashify 2.0",
},


      ],
    };
  },
  methods: {
    initCheckAll() {
        const checkAll = document.getElementById("checkAll");
        if (checkAll) {
            checkAll.onclick = function() {
                const checkboxes = document.querySelectorAll('.table-check input[type="checkbox"]');
                for(var i=0; i < checkboxes.length; i++) {
                    checkboxes[i].checked = this.checked;
                }
            }
        }
    }
  }
};
</script>

<template>
  <div class="col-xl-12">
   
    <div class="card">
          <div class="card-header">
            <h4 class="card-title">{{ title }}</h4>
          </div>
          <div class="card-body" >
            <!-- <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      class="form-select form-select-sm"
                      v-model="todoperPage"
                      size="sm"
                      :options="todoOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
            </div> -->
            <div class="table-responsive" >
              <b-table
                :items="orderData"
                responsive="sm"
                :per-page="todoperPage"
                :current-page="todocurrentPage"
                :filter="todoFilter"
                :filter-included-fields="todofilterOn"
                @filtered="todoFiltered"
              ></b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="todocurrentPage"
                      :total-rows="todoList"
                      :per-page="todoperPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>
