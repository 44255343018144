<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "./widget.vue";


import Orders from "./orders.vue";

import {

  groupedBarChart,


} from "@/views/pages/charts/bar/data";


import {


  stackedColumnsChart

} from "@/views/pages/charts/column/data";



/**
 * Sales-Dashboard component
 */
export default {
  page: {
    title: "Sales Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Remax Crest",
        },
        {
          text: "Dashbaoard",
          active: true,
        },
      ],

      groupedBarChart: groupedBarChart,
      stackedColumnsChart: stackedColumnsChart,

      listing : [
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
        {address : '3631 No.3 Rd Richmond', agent : 'Micheal Lee', date : 'May 12, 2025'},
      ]
    };
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    // AgentCountAnalytics,
    Orders,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Stat />
    <div class="row">



    </div>
    <!-- end row-->


    <div class="row" v-if="0">
      <div class="col-md-7">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Pending Expense</h4>
          </div><!-- end card header -->
          <div class="card-body" style="height: 400px">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Invoice #</th>
                    <th>Payee</th>
                    <th>Due Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1233523</th>
                    <td>BELLEVUE PROPERTIES</td>
                    <td>Feb 28, 2025</td>
                    <td>Internet Fee</td>
                    <td>$1,234.23</td>
                  </tr>
                  <tr>
                    <th scope="row">1233524</th>
                    <td> BC CHILDREN S HOSPITAL FOUNDATION</td>
                    <td>Feb 28, 2025</td>
                    <td>Monthly Rent</td>
                    <td>$6,232.0</td>
                  </tr>
                  <tr>
                    <th scope="row">1233523-2</th>
                    <td> CANACON INVESTMENTS GROUP INC</td>
                    <td>Feb 28, 2025</td>
                    <td>Courier Fee</td>
                    <td>$838</td>
                  </tr>
                  <tr>
                    <th scope="row">1233523-2</th>
                    <td> CANACON INVESTMENTS GROUP INC</td>
                    <td>Feb 28, 2025</td>
                    <td>Courier Fee</td>
                    <td>$838</td>
                  </tr>
                  <tr>
                    <th scope="row">1233523-2</th>
                    <td> CANACON INVESTMENTS GROUP INC</td>
                    <td>Feb 28, 2025</td>
                    <td>Courier Fee</td>
                    <td>$838</td>
                  </tr>
                  <tr>
                    <th scope="row">1233523-2</th>
                    <td> CANACON INVESTMENTS GROUP INC</td>
                    <td>Feb 28, 2025</td>
                    <td>Courier Fee</td>
                    <td>$838</td>
                  </tr>
                  <tr>
                    <th scope="row">1233523-2</th>
                    <td> CANACON INVESTMENTS GROUP INC</td>
                    <td>Feb 28, 2025</td>
                    <td>Courier Fee</td>
                    <td>$838</td>
                  </tr>
                </tbody><!-- end table -->
              </table><!-- end table -->
            </div><!-- end table responsive -->
          </div><!-- end card body -->
        </div><!-- end card -->
      </div>
      <!-- end col-->

      <div class="col-md-5">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Expense History</h4>
          </div>
          <!-- end card header -->
          <div class="card-body" style="height: 400px">
            <apexchart class="apex-charts" height="350" dir="ltr" :series="stackedColumnsChart.series"
              :options="stackedColumnsChart.chartOptions"></apexchart>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->


    </div>


    <div class="row">

      <div class="col-xl-3 col-lg-3">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">RECENT EVENTS</h4>
          </div>


          <div class="card-body" >

            <b-carousel id="carousel-fade" indicators controls>
              <b-carousel-slide :img-src="require('@/assets/images/small/img-13.jpg')" ></b-carousel-slide>
              <b-carousel-slide :img-src="require('@/assets/images/small/img-13.jpg')" ></b-carousel-slide>
              <b-carousel-slide :img-src="require('@/assets/images/small/img-13.jpg')"  ></b-carousel-slide>
            </b-carousel>

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-md-8">

        <div class="row">

          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Total COUNT</h4>
              </div>


              <!-- end card header -->
              <div class="card-body" >
                <apexchart class="apex-charts"  dir="ltr" :series="groupedBarChart.series"
                  :options="groupedBarChart.chartOptions"></apexchart>
              </div>
            </div>
            <!--end card-->
          </div>
          <!-- end col-->

          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Total COUNT</h4>
              </div>


              <!-- end card header -->
              <div class="card-body" >
                <apexchart class="apex-charts"  dir="ltr" :series="groupedBarChart.series"
                  :options="groupedBarChart.chartOptions"></apexchart>
              </div>
            </div>
            <!--end card-->
          </div>
          <!-- end col-->
        </div>

      </div>





    </div>
    <!-- end row-->




    <div class="row">
      <Orders :title="'AGENT ACTIVITY'" />
    </div>

    <div class="row">
      <div class="col-md-4">

        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">New Listing</h5>

            <div data-simplebar>
              <ul class="list-group list-group-flush" v-for="(data, idx) in listing" :key="'listing_'+idx">
                <li class="list-group-item py-3 px-0">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle font-size-12">
                          <i class="fas fa-user"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="font-size-14 mb-1 text-truncate">
                        <a href="#" class="text-dark">{{ data.address }}</a>
                      </h5>
                      <ul class="list-inline font-size-13 text-muted">
                        <li class="list-inline-item">
                          <a href="#" class="text-muted">
                            <i class="uil uil-user me-1"></i> {{ data.agent }}
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <i class="uil uil-calender me-1"></i> {{ data.date }}
                        </li>
                      </ul>
                    </div>
                    <div class="flex-shrink-0 ms-2">
                      <div class="badge badge-soft-success ms-2">
                        MLS Residential

                      </div>
                    </div>
                  </div>
                </li>









              </ul>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col-->

      <div class="col-md-4">

        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">New Deal</h5>

            <div data-simplebar>
              <ul class="list-group list-group-flush">
                <li class="list-group-item py-3 px-0" v-for="(data, idx) in listing" :key="'deal_'+idx">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle font-size-12 "
                          style="background-color:rgba(243,78,78, 0.15);">
                          <i class="fas fa-user  text-danger"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="font-size-14 mb-1 text-truncate">
                        <a href="#" class="text-dark">{{ data.address }}</a>
                      </h5>
                      <ul class="list-inline font-size-13 text-muted">
                        <li class="list-inline-item">
                          <a href="#" class="text-muted">
                            <i class="uil uil-user me-1"></i> {{ data.agent }}
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <i class="uil uil-calender me-1"></i> {{ data.date }}
                        </li>
                      </ul>
                    </div>
                    <div class="flex-shrink-0 ms-2">
                      <div class="badge badge-soft-danger ms-2">
                        $1,232,232

                      </div>
                    </div>
                  </div>
                </li>
               

              </ul>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col-->

      <div class="col-md-4">

        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">New RTA</h5>

            <div data-simplebar>
              <ul class="list-group list-group-flush">

                <li class="list-group-item py-3 px-0" v-for="(data, idx) in listing" :key="'rta_'+idx">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle font-size-12  bg-soft-purple">
                          <i class="fas fa-user"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="font-size-14 mb-1 text-truncate">
                        <a href="#" class="text-dark">{{ data.address }}</a>
                      </h5>
                      <ul class="list-inline font-size-13 text-muted">
                        <li class="list-inline-item">
                          <a href="#" class="text-muted">
                            <i class="uil uil-user me-1"></i>{{ data.agent }}
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <i class="uil uil-calender me-1"></i> {{ data.date }}
                        </li>
                      </ul>
                    </div>
                    <div class="flex-shrink-0 ms-2">
                      <div class="badge badge-soft-purple ms-2  font-size-12">
                        MUL023

                      </div>
                    </div>
                  </div>
                </li>

                


              </ul>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col-->


    </div>
  </Layout>
</template>
